export default {
  path: "/production",
  name: "production",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/production/plan",
  children: [
    {
      name: 'plan',
      path: "plan",
      meta: { title: "生产计划", permission: "production_plan", siderbar:{siderbar_id:10, siderbar_child_id:7} },
      component: () => import("@/views/production/productionPlan/index"),
    },
    {
      path: "detial",
      meta: {
        title: "生产计划详情",
        notMenu: true,
        permission: "production_plan",
      },
      component: () => import("@/views/production/productionDetial/index"),
    },
    {
      name: 'task',
      path: "task",
      meta: { title: "生产任务", permission: "production_task", siderbar:{siderbar_id:10, siderbar_child_id:8} },
      component: () => import("@/views/production/productionTask/index"),
    },
    {
      name: 'record',
      path: "record",
      meta: { title: "生产记录", permission: "production_record", siderbar:{siderbar_id:10, siderbar_child_id:9} },
      component: () => import("@/views/production/productionRecord/index"),
    },
    {
      name: 'inspection',
      path: "inspection",
      meta: { title: "效果验证", permission: "production_sampling_inspection", siderbar:{siderbar_id:10, siderbar_child_id:10} },
      component: () => import("@/views/production/production_sampling_inspection/index.vue"),
    },
     {
      name: 'inspectionDetail',
      path: "inspectionDetail",
      meta: {
        title: "生产抽检审批",
        notMenu: true,
        permission: "inspectionDetail",
      },
      component: () => import("@/views/production/production_sampling_inspection/inspectionDetail.vue"),
    },
    {
      name: 'Sop',
      path: "Sop",
      meta: { title: "生产SOP", permission: "production_SOP", siderbar:{siderbar_id:10, siderbar_child_id:0} },
      component: () => import("@/views/production/configuration/productionSOP/index.vue"),
    },
    {
      name: 'SOP_create',
      path: "SOP_create",
      meta: { title: "创建SOP", permission: "SOP_create", siderbar:{siderbar_id:10, siderbar_child_id:null} },
      component: () => import("@/views/production/configuration/SOPCreate/index.vue"),
    },
    {
      path: "SOP_detail",
      meta: {
        title: "生产SOP详情",
        notMenu: true,
        permission: "production_SOP",
        siderbar:{siderbar_id:10, siderbar_child_id:null}
      },
      component: () => import("@/views/production/configuration/productionSOPDetial/index"),
    },
    {
     path: "production_raw_material",
     name: "production_raw_material",
     meta: {
      title: "生产原料",
      permission: "production_raw_material",
      siderbar:{siderbar_id:10, siderbar_child_id:1}
     },
      component: () => import("@/views/production/configuration/productionRawMaterial/index"),
    },
    {
      name: 'teamsGroups',
      path: "teamsGroups",
      meta: {
        title: "生产班组",
        permission: "production_teamsGroups",
        siderbar:{siderbar_id:10, siderbar_child_id:2}
      },
      component: () => import("@/views/production/configuration/teamsGroups/index"),
    },
    {
      name: 'productionProcesses',
      path: "productionProcesses",
      meta: { title: "生产工序", permission: "production_productionProcesses", siderbar:{siderbar_id:10, siderbar_child_id:3} },
      component: () => import("@/views/production/configuration/productionProcesses/index"),
    },
    {
      name: 'qualityTesting',
      path: "qualityTesting",
      meta: { title: "质检工序", permission: "qualityTesting_productionProcesses", siderbar:{siderbar_id:10, siderbar_child_id:4} },
      component: () => import("@/views/production/configuration/qualityTesting/index"),
    },
    {
      name: 'brand',
      path: "brand",
      meta: { title: "辅料品牌", permission: "production_brand", siderbar:{siderbar_id:10, siderbar_child_id:5} },
      component: () => import("@/views/production/configuration/productionBrand/index"),
    },
    {
      name:'unit',
      path: "unit",
      meta: { title: "辅料单位", permission: "production_unit",siderbar:{siderbar_id:10, siderbar_child_id:6} },
      component: () => import("@/views/production/configuration/unit/index"),
    },
  ],
};
