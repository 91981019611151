import pageOperation from '@/pageOperation'
import pageMenu from '@/pageMenu'
export function removeItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}
export function removeItemBatchIndexs(items, item) {
  let index = [...items].findIndex(_item => _item.indexs == item.indexs);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}
export function removeItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function replaceItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1, item);
  }
  return items
}

export function insertItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function insertItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function removeItemBatch(items, item) {
  let index = [...items].findIndex(_item => _item.key == item.key);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function removeItemSalesOrder(items, item) {
  let index = [...items].findIndex(_item => _item.sales_order_id == item.sales_order_id);
  if(index != -1) {
    items.splice(index, 1);
  }
  return items
} 


export function insertBillsItem(items, item) {
  items.splice(0, 0, item);
  return items
}


export function removeBillsItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id)
  if(index != -1) {
    items.splice(index, 1)
  }
  return items
}

export function removeBillsItemreCord(items, item) {
  let index = [...items].findIndex(_item => _item.billsId == item.billsId)
  if(index != -1) {
    items.splice(index, 1)
  }
  return items
}
// 获取具体高级筛选的参数值
export function searchOption(items, name) {
  return items.filter(_item =>_item.name == name)[0]//查找高级筛选需要无缝滚动的对象
}
// 修改具体高级筛选的参数值
export function changeOption(items, name, value) {
  console.log(items);
  let index = items.findIndex(_item => _item.name == name)
  if(index !=-1 ) {
    items.splice(index, 1, value)
    console.log(1, index);
  }
  return items  
}



// 订单保存功能
export function InvoiceSave(name, data) {
  console.log(window.indexedDB);
}

// 页面细化操作处理
export function findKeyByName(name){
   const operation = pageOperation.find(op => op.name === name);
   return operation ? operation.key : null; // 如果找到，返回 key，否则返回 null
}
// 获取侧边栏权限
export function pageOperationMenusPermissions(permissions){
  if(permissions==null){
    return true
  }
  const menus=JSON.parse(JSON.stringify(pageMenu));
  const data=[]
  for (let i = 0; i < menus.length; i++) {
  //   第一层判断大分类
    if(permissions[i].length!=0){
      // 如果大分类的长度为0 就表示这个没有这个分类 pageMenu[i]
      const bigData=menus[i] //当前添加的大分类
      const pageData=[] //大分类下面的小页面
      for (let j = 0; j < menus[i].submenus.length; j++) {
        let subPage=menus[i].submenus[j]
        let  pageOperation=[]
        if(permissions[i][j]!=null){
          subPage.operation.forEach(item=>{
            if(permissions[i][j].includes(item.key)){
              pageOperation.push(item)
            }
          })
          subPage.operation=pageOperation
          pageData.push(subPage)
        }
      }
      bigData.submenus=pageData
      // 进行加载里面的小页面
      data.push(bigData)
    }
  }
  // console.log(123,data)
  return data;
}

// 进行数据删除之后，重新渲染以及更新分页索引值

export function getPageIndex({ total_items, select_items, pageSize, currentPage }) {

  // 转为数组
  // 计算批量操作后剩余的总数据量
  const remainingTotalItems = total_items - select_items.length;
  // 计算数据的总数量
  const totalPages = Math.ceil( remainingTotalItems/pageSize )
  // 确保当前页码在总页数范围内
  if (currentPage > totalPages) {
    currentPage = totalPages;
  }
  if(currentPage == 0) {
    currentPage = 1
  }
  // console.log('总页数:'+total_items+'批量出库的页数'+select_items+'剩余数量:'+remainingTotalItems+'请求页数:'+pageSize+'数据页码:'+ totalPages+'当前页码:'+currentPage);

  return currentPage
}

export function getRefs(that, keyword) {
  that.$nextTick(()=>{  
    const refs = that.$refs.child.$refs//包含默认值的实例对象数组
    console.log('that', refs);
    const filteredKeys = Object.keys(refs).filter(item => item.includes(keyword));//筛选实例对象
    filteredKeys.forEach(_item => {
      console.log(refs[_item]);
      refs[_item].filterOption()
    })
  })
}
export function get_child_Refs(that, keyword) {
  that.$nextTick(()=>{
    const refs = that.$refs//包含默认值的实例对象数组
    console.log('that', refs, Object.keys(refs));
    const filteredKeys = Object.keys(refs).filter(item => item.includes(keyword));//筛选实例对象
    console.log('filteredkeys', filteredKeys);
    filteredKeys.forEach(_item => {
      console.log(refs[_item]);
      refs[_item].filterOption()
    })
  })
}
 
export default { removeItem,removeItemBatchIndexs, removeItemM,  replaceItem, insertItem, insertItemM, removeItemBatch, removeItemSalesOrder, insertBillsItem, removeBillsItem, removeBillsItemreCord, InvoiceSave, getPageIndex, searchOption, changeOption, findKeyByName, pageOperationMenusPermissions, getRefs, get_child_Refs}