export default {
  namespaced:true,
  state:() => ({
    purchase_currentTab:'purchaseCreate',//采购模块开单tab状态的保存
    purchase_record_currentTab:'goods',//采购记录模块tab状态保存
    sale_currentTab:"saleCreate",//销售模块开单tab状态的保存
    saleRecord_currentTab: "saleRecord",//销售记录模块状态的保存
    instock_currentTab: "goods",//入库模块的tab状态的保存
    outstock_currentTab: "goods",//出库模块的tab状态的保存
    inventory_currentTab:'inventoryCreate',//盘点模块开单的tab状态的保存
    inventory_record_currentTab:'inventory',//盘点记录模块状态保存
    allocation_record_currentTab:'goods_allocation',//调拨记录模块状态保存
    allocation_currentTab:'goods_allocation_create',//调拨模块开单tab状态的保存
    flow_currentTab:'goods_flow',//流水模块tab状态的保存
    stock_report_currentTab:"goods_stockReport",//库存模块tab状态的保存
    goods_information_currentTab:2,//产品信息tab状态的保存
    searchType:0,//产品信息数据分析的状态保存
  }),
  mutations: {
    setStatus(state, { option, type }) {
      //  console.log('模块', 'setStatus');
       if(type=='purchase') state.purchase_currentTab = option
       if(type=='purchase_record') state.purchase_record_currentTab = option
       if(type=="sale") state.sale_currentTab = option
       if(type=="saleRecord")state.saleRecord_currentTab = option
       if(type=="instock")state.instock_currentTab = option
       if(type=="outstock")state.outstock_currentTab = option
       if(type=="inventory")state.inventory_currentTab = option
       if(type=="inventory_record")state.inventory_record_currentTab = option
       if(type=="allocation_record")state.allocation_record_currentTab = option
       if(type=='allocation')state.allocation_currentTab = option
       if(type=='flow')state.flow_currentTab = option
       if(type=='stock_report')state.stock_report_currentTab = option
       if(type=='goods_information'){
        state.goods_information_currentTab = option
        state.searchType = 0 
       }
    },
    set_info_value(state, item) {
      const { key, value } = item
      state[key] = value
    }
  },
  actions: {
    setStatus({ commit }, params) {
      commit('setStatus', params)
      commit('systemPermissons/save_operation_permissions', null, { root: true })
    }
  }
}

