import functions from '@/utils/functions';
import Vue from 'vue'
// import VueRouter from 'vue-router'
import router from './router/index'
import store from './store'
import App from './App.vue'
// import Vuex from 'vuex'

import Viser from 'viser-vue'
// import Antd from 'ant-design-vue/es';
import htmlToPdf from '@/utils/htmlToPdf'
// 浏览器派发事件
import { dispatchEventStroage, dispatchEventCaches } from '@/utils/windowDispatch';

// 监听元素宽度调整
import ElementResizeDetectorMaker from "element-resize-detector"
// 时钟
import VueTutClock from 'vue-tut-clock';
import '@/utils/timeOutClear.js'
Vue.use(VueTutClock)
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
let  defaultLanguage='zh'
if(window.localStorage.getItem('systemLanguage')){
  defaultLanguage=window.localStorage.getItem('systemLanguage')
}
const i18n=new VueI18n({
  locale: defaultLanguage, // 默认语言
  messages:{
    zh:require('@/language/zh.json'),
    en:require('@/language/en.json'),
  }
});
// import Antd from 'ant-design-vue';
// import "ant-design-vue/dist/antd.less";

import '@/assets/common.css'
// import  "../node_modules/moment/locale/zh-cn"

// 引入echarts以及中国地图china.js
// import * as echarts from 'echarts';
import '../node_modules/echarts/map/js/china';
import '@/assets/font/iconfont.css'

// 引入公共样式
import Print from 'vue-print-nb'
// 解决Chrome51 版本以后，Chrome 增加了新的事件捕获机制－Passive Event Listeners
import 'default-passive-events'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.$functions = functions;
Vue.prototype.$erd = ElementResizeDetectorMaker()
// Vue.use(VueRouter);
// Vue.use(Vuex);
// 全局引用
Vue.use(Viser);
// Vue.use(Antd);
Vue.use(Print);
Vue.use(htmlToPdf);
Vue.use(dispatchEventStroage)
Vue.use(dispatchEventCaches)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
