export default {
  path: "/basicData",
  name: "basicData",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/basicData/client_classification",
  children: [
    {
      name: 'company',
      path: "company",
      meta: { title: "企业信息", permission: "company", siderbar:{siderbar_id:11, siderbar_child_id:0} },
      component: () => import("@/views/basicData/company/index"),
    },
    {
      name: 'department',
      path: "department",
      meta: { title: "部门管理", permission: "department", siderbar:{siderbar_id:11, siderbar_child_id:1} },
      component: () => import("@/views/basicData/department/index"),
    },
    {
      name: 'supplier',
      path: "supplier",
      meta: { title: "供应商管理", permission: "supplier", siderbar:{siderbar_id:11, siderbar_child_id:2} },
      component: () => import("@/views/basicData/supplier/index"),
    },
    {
      name: 'warehouse',
      path: "warehouse",
      meta: { title: "仓库管理", permission: "warehouse", siderbar:{siderbar_id:11, siderbar_child_id:3} },
      component: () => import("@/views/basicData/warehouse/index"),
    },
    {
      name: 'settlement_account',
      path: "settlement_account",
      meta: { title: "结算账户", permission: "settlement_account", siderbar:{siderbar_id:11, siderbar_child_id:4} },
      component: () => import("@/views/basicData/settlementAccount/index"),
    },
    {
      name: 'revenue_expenditure_items',
      path: "revenue_expenditure_items",
      meta: {
        title: "收支项目",
        permission: "revenue_expenditure_items",
        siderbar:{siderbar_id:11, siderbar_child_id:5}
      },
      component: () =>
        import("@/views/basicData/revenueExpenditureItems/index"),
    },
    {
      name: 'saler_setting',
      path: "saler_setting",
      meta: { title: "销售指标", permission: "saler_setting", siderbar:{siderbar_id:11, siderbar_child_id:6} },
      component: () => import("@/views/basicData/saler/index.vue"),
    },
  ],
};
