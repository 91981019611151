export default {
    path: "/client",
    name: "client",
    redirect: "/client/client_manage",
    component: () => import("@/layouts/BaseLayout"),
    children: [
          {
            name:'client',
            path: "client",
            meta: { title: "客户信息", permission: "client", siderbar:{siderbar_id:8, siderbar_child_id:0} },
            component: () => import("@/views/client/client/index"),
          },
          {
            name: 'follow',
            path: "follow",
            meta: { title: "客户跟进", permission: "follow", siderbar:{siderbar_id:8, siderbar_child_id:2} },
            component: () => import("@/views/client/follow/index"),
          },
          {
            name: 'analyse',
            path: "analyse",
            meta: { title: "客户分析", permission: "analyse", siderbar:{siderbar_id:8, siderbar_child_id:3} },
            component: () => import("@/views/client/analyse/index"),
          },
          {
            name: 'business_opportunity',
            path: "business_opportunity",
            meta: { title: "商业机会", permission: "business_opportunity", siderbar:{siderbar_id:8, siderbar_child_id:4} },
            component: () => import("@/views/client/businessOpportunity/index")
          },
           {
            name: 'agent_clients',
            path: "agent_clients",
            meta: { title: "经销旗下", permission: "agent_clients", siderbar:{siderbar_id:8, siderbar_child_id:1} },
            component: () => import("@/views/client/agent_client/index.vue")
          }
    ],
};
