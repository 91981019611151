export default {
  path: "/after_sale",
  name: "after_sale",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/after_sale/after_service",
  children: [   
    {
      path: "after_service",
      name: 'after_service',
      meta: { title: "售后服务", permission: "after_service", siderbar:{siderbar_id:9, siderbar_child_id:0} },
      component: () => import("@/views/afterSale/afterService/index"),
    },
  ],
};