import request from '@/utils/request';


// GetToken
export function getToken(data) {
  return request({ url: `/user/get_token/`, method: 'post', data }, false)
}

// RefreshToken
export function refreshToken(data) {
  return request({ url: `/user/refresh_token/`, method: 'post', data })
}

// GetInfo
export function getInfo(params) {
  return request({ url: `/user/info/`, method: 'get', params })
}

// SetPassword
export function setPassword(data) {
  return request({ url: `/user/set_password/`, method: 'post', data })
}

// 常用功能
export function getCommonFunctions(params) {
  return request({ url: `/user/common_functions/`, method: 'get', params })
}

// 设置常用功能
export function setCommonFunctions(data) {
  return request({ url: `/user/set_common_functions/`, method: 'post', data })
}
export function getUserImages(params) {
  return request({ url: `/media/user_images/`, method: 'get', params })
}
// 获取产品图片
export function getGoodsImages(params) {
  return request({ url: `/media/goods_images/`, method: 'get', params })
}
export function getTeamImages(params) {
  return request({ url: `/media/team_images/`, method: 'get', params })
}
export function getLogoImages(params) {
  return request({ url: `/media/team_images_favicon/`, method: 'get', params })
}
export function getProductionCheckFirstFiles(params) {
  return request({ url: `/media/production_check_files_first/`, method: 'get', params })
}
export function getProductionCheckSecondFiles(params) {
  return request({ url: `/media/production_check_files_second/`, method: 'get', params })
}
export function getProductionCheckThirdFiles(params) {
  return request({ url: `/media/production_check_files_third/`, method: 'get', params })
}
export function getAfterSaleFiles(params) {
  return request({ url: `/media/after_sale_files/`, method: 'get', params })
}
export function getNoticeFiles(params) {
  return request({ url: `/media/notice_files/`, method: 'get', params })
}
export function getResearchFiles(params) {
  return request({ url: `/media/research_files/`, method: 'get', params })
}