export default {
  path: "/purchasing",
  name: "purchasing",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/purchasing/purchase_create",
  children: [
    {
      name: 'purchase_create',
      path: "purchase_create",
      meta: { title: "采购开单", permission: "purchase_create", keepAlive:false, siderbar:{ siderbar_id:3, siderbar_child_id:null } },
      component: () => import("@/views/purchasing/purchaseCreate/index"),
    },
    {
      name: 'purchase_record',
      path: "purchase_record",
      meta: { title: "采购记录", permission: "purchase_record", keepAlive:false, siderbar:{ siderbar_id:3, siderbar_child_id:0 } },
      component: () => import("@/views/purchasing/purchaseRecord/index"),
    },
    {
      path: "purchase_record_detail",
      meta: {
        title: "采购记录详情",
        permission: "purchase_record",
        notMenu: true,
        siderbar:{ siderbar_id:3, siderbar_child_id:null }
      },
      component: () => import("@/views/purchasing/purchaseRecordDetail/index"),
    },
    {
      name: 'purchase_return_create',
      path: "purchase_return_create",
      meta: { title: "采购退货", permission: "purchase_return_create", siderbar:{ siderbar_id:3, siderbar_child_id:null } },
      component: () => import("@/views/purchasing/purchaseReturnCreate/index"),
    },
    {
      name: 'return_record',
      path: "return_record",
      meta: { title: "采购退货记录", permission: "return_record", keepAlive:false, siderbar:{ siderbar_id:3, siderbar_child_id:1 } },
      component: () => import("@/views/purchasing/returnRecord/index"),
    },
    {
      path: "return_record_detail",
      meta: {
        title: "退货记录详情",
        permission: "return_record",
        notMenu: true,
        siderbar:{ siderbar_id:3, siderbar_child_id:null }
      },
      component: () => import("@/views/purchasing/returnRecordDetail/index"),
    },
  ],
};
