
export default {
  path: "/finance",
  name: "finance",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/finance/flow",
  children: [
    {
      name: 'payment',
      path: "payment",
      meta: { title: "付款", permission: "payment", siderbar:{siderbar_id:6, siderbar_child_id:1} },
      component: () => import("@/views/finance/payment/index"),
    },
    {
      name: 'payment_create',
      path: "payment_create",
      meta: { title: "付款新增", notMenu: true, permission: "payment", siderbar:{siderbar_id:6, siderbar_child_id:null}},
      component: () => import("@/views/finance/paymentCreate/index"),
    },
    {
      path: "payment_detail",
      meta: { title: "付款详情", notMenu: true, permission: "payment", siderbar:{siderbar_id:6, siderbar_child_id:null} },
      component: () => import("@/views/finance/paymentDetail/index"),
    },
    {
      name: 'collection',
      path: "collection",
      meta: { title: "收款", permission: "collection", siderbar:{siderbar_id:6, siderbar_child_id:3} },
      component: () => import("@/views/finance/collection/index"),
    },
    {
      name: 'is_advance',
      path: 'is_advance',
      meta: { title:'客户预付', permission: 'is_advance', siderbar:{siderbar_id:6, siderbar_child_id:4} },
      component: () => import("@/views/finance/is_advance/index")
    },
    {
      name: 'collection_create',
      path: "collection_create",
      meta: {
        title: "收款新增",
        notMenu: true,
        permission: "collection",
        keepAlive:false,
        siderbar:{siderbar_id:6, siderbar_child_id:null}
      },
      component: () => import("@/views/finance/collectionCreate/index"),
    },
    {
      path: "collection_detail",
      meta: {
        title: "收款详情",
        notMenu: true,
        permission: "collection",
        siderbar:{siderbar_id:6, siderbar_child_id:null}
      },
      component: () => import("@/views/finance/collectionDetail/index"),
    },
    {
      name: 'arrears_payable',
      path: "arrears_payable",
      meta: { title: "应付欠款", permission: "arrears_payable", siderbar:{siderbar_id:6, siderbar_child_id:0} },
      component: () => import("@/views/finance/arrearsPayable/index"),
    },
    {
      path: "arrears_payable_detail",
      meta: {
        title: "应付欠款详情",
        notMenu: true,
        permission: "arrears_payable",
      },
      component: () => import("@/views/finance/arrearsPayableDetail/index"),
    },
    {
      name: 'arrears_receivable',
      path: "arrears_receivable",
      meta: { title: "应收欠款", permission: "arrears_receivable", keepAlive:false, siderbar:{siderbar_id:6, siderbar_child_id:2} },
      component: () => import("@/views/finance/arrearsReceivable/index"),
    },
    {
      path: "arrears_receivable_detail",
      meta: {
        title: "应收欠款详情",
        notMenu: true,
        permission: "arrears_receivable",
      },
      component: () => import("@/views/finance/arrearsReceivableDetail/index"),
    },
    {
      name: 'account_transfer',
      path: "account_transfer",
      meta: { title: "账户转账", permission: "account_transfer", siderbar:{siderbar_id:6, siderbar_child_id:5} },
      component: () => import("@/views/finance/accountTransfer/index"),
    },
    {
      name: 'income_and_pay',
      path: "income_and_pay",
      meta: { title: "日常收支", permission: "income_and_pay", siderbar:{siderbar_id:6, siderbar_child_id:6} },
      component: () => import("@/views/finance/incomeAndPay/index"),
    },
    {
      name: 'flow',
      path: "flow",
      meta: { title: "资金流水", permission: "finance_flow", siderbar:{siderbar_id:6, siderbar_child_id:7} },
      component: () => import("@/views/finance/flow/index"),
    },
    {
      path: "flow_detail",
      meta: { title: "资金流水详情", notMenu: true, permission: "finance_flow", siderbar:{siderbar_id:6, siderbar_child_id:null} },
      component: () => import("@/views/finance/flowDetail/index"),
    },
    // {
    //   path: 'purchase_statement',
    //   meta: { title: '采购对账单', permission: 'purchase_statement' },
    //   component: () => import('@/views/finance/purchaseStatement/index'),
    // },
    // {
    //   path: 'transaction_record',
    //   meta: { title: '往来记录', permission: 'transaction_record' },
    //   component: () => import('@/views/finance/transactionRecord/index'),
    // },
    // {
    //   path: 'sales_statement',
    //   meta: { title: '销售对账单', permission: 'sales_statement' },
    //   component: () => import('@/views/finance/salesStatement/index'),
    // },
  ],
};
