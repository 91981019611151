import request from '@/utils/request';

// 正装产品
 
export function filter_not_sample(params) {
  return request({ url: `/goods/options/filter_not_sample/`, method: 'get', params })
}
export function departmentsFilterIsPerformance(params) {
  return request({ url: `/departments/options/filter_is_performance/`, method: 'get', params })
}
// 一级分类
export function primaryCategoryOption(params) {
  return request({ url: `/primary_goods_category/options/`, method: 'get', params })
}
// 二级分类
export function secondCategoryOption(params) {
  return request({ url: `/second_goods_category/options/`, method: 'get', params })
}
// RoleOption
export function roleOption(params) {
  return request({ url: `/roles/options/`, method: 'get', params })
}

// UserOption
export function userOption(params) {
  return request({ url: `/users/options/`, method: 'get', params })
}

// TeamOption
export function teamOption(params) {
  return request({ url: `/teams/options/`, method: 'get', params })
}

// 产品
export function materialsOption(params) {
  return request({ url: `/materials/options/`, method: 'get', params })
}
// 供应商
export function suppliersOption(params) {
  return request({ url: `/suppliers/options/`, method: 'get', params })
}

// 生产供应商
export function productionSuppliersOption(params) {
  return request({ url: `/production_supplier/options/`, method: 'get', params })
}
export function production_suppliersOption(params) {
  return request({ url: `/suppliers/options/production_supplier/`, method: 'get', params })
}
// 生产sop
export function production_dateOption(params) {
  return request({ url:`/production_date/options/`, method:'get', params })
}

// 客户联系人
export function clients_contact(params) {
  return request({ url:`/clients_contact/options/`, method:'get', params })
}
// 生产班组
export function production_teamOption(params) {
  return request({ url:`/production_team/options/`, method:'get', params })
}
// 企业信息
export function companyOption(params) {
  return request({ url: `/companies/options/`, method: 'get', params })
}

// 行政部门
export function departmentsOption(params) {
  return request({ url: `/departments/options/`, method: 'get', params })
}

// 客户
export function clientsOption(params) {
  return request({ url: `/clients/options/`, method: 'get', params })
}
// 仓库
export function warehousesOption(params) {
  return request({ url: `/warehouses/options/`, method: 'get', params })
}


// 产品分类
export function goodsClassificationOption(params) {
  return request({ url: `/goods_category/options/`, method: 'get', params })
}

// 产品单位
export function goodsUnitOption(params) {
  return request({ url: `/goods_units/options/`, method: 'get', params })
}
// 应用方向
export function goodsApplicationDirectionOption(params) {
  return request({ url: `/application_direction/`, method: 'get', params })
}
// 生产产品产品单位
export function productionUnitOption(params) {
  return request({ url: `/production_units/options/`, method: 'get', params })
}
// 产品品牌
export function goodsBrandOption(params) {
  return request({ url: `/goods_brands/options/`, method: 'get', params })
}
// 生产产品品牌
export function productionBrandOption(params) {
  return request({ url: `/production_brand/options/`, method: 'get', params })
}
// 产品选项
export function inventoriesOption(params) {
  return request({ url: `/inventories/options/`, method: 'get', params })
}
// 样品产品选项
export function inventoriesOptionSample(params) {
  return request({ url: `/inventories/options/get_samples/`, method: 'get', params })
}
// 产品选项
export function goodsOption(params) {
  return request({ url: `/goods/options/`, method: 'get', params })
}
// 全部产品选项
export function goodsAllOption(params) {
  return request({ url: `/goods/options/filter_all/`, method: 'get', params })
}
// 产品选项
export function goodsSampleOption(params) {
  return request({ url: `/goods/options/filter_sample/`, method: 'get', params })
}


// 固定资产
export function fixedAssetOption(params) {
  return request({ url: `/fixed_asset/options/`, method: 'get', params })
}



// 结算账户
export function accountsOption(params) {
  return request({ url: `/accounts/options/`, method: 'get', params })
}
// 采购单据
export function purchaseOrdersOption(params) {
  return request({ url: `/purchase_orders/options/`, method: 'get', params })
}
// 销售单据
export function saleOrdersOption(params) {
  return request({ url: `/sales_orders/options/`, method: 'get', params })
}
// 销售退货单据
export function saleReturnOrdersOption(params) {
  return request({ url: `/sales_return_orders/options/`, method: 'get', params })
}
// 批次
export function batchsOption(params) {
  return request({ url: `/batchs/options/`, method: 'get', params })
}

// 企业信息
export function companyArrearsOption(params) {
  return request({ url: `/company_arrears/options/`, method: 'get', params })
}

// 供应商
export function supplierArrearsOption(params) {
  return request({ url: `/supplier_arrears/options/`, method: 'get', params })
}

// 行政部门
export function departmentArrearsOption(params) {
  return request({ url: `/department_arrears/options/`, method: 'get', params })
}

// 客户
export function clientArrearsOption(params) {
  return request({ url: `/client_arrears/options/`, method: 'get', params })
}
// 结算项目
export function chargeItemsOption(params) {
  return request({ url: `/charge_items/options/`, method: 'get', params })
}
export function agentClientsOption(params) {
  return request({ url: `/agent_clients/options/`, method: 'get', params })
}
export function salesOrdersGoodsOption(params) {
  return request({ url: `/sales_orders_goods/options/`, method: 'get', params })
}
// 经销商
export function client_agentOption(params) {
  return request({ url:`/clients/options/client_agent`, method:'get', params })
}

// // 业务经理
// export function managerOption(params) {
//   return request({ url: `/users/options/`, method: 'get', params })
// }
// // 合同
// export function contractOption(params) {
//   return request({ url: `/contract_orders/options/`, method: 'get', params })
// }

// // 合同原料
// export function contractMaterialsOption(params) {
//   return request({ url: `/contract_materials/options/`, method: 'get', params })
// }

// // 采购单原料
// export function purchaseMaterialsOption(params) {
//   return request({ url: `/purchase_materials/options/`, method: 'get', params })
// }

// // 装箱清单
// export function packingOrdersOption(params) {
//   return request({ url: `/packing_orders/options/`, method: 'get', params })
// }

// // 装箱清单原料
// export function packingMaterialsOption(params) {
//   return request({ url: `/packing_materials/options/`, method: 'get', params })
// }