export default {
  namespaced:true,
  state:()=> ({
    pageMenus:null,//菜单数据
    sider_item_index:0,//保存侧边栏的active id
    page_menus:null,//保存最新的sider权限对象
  }),
  mutations: {
    differentiate_types(state, item) {
      state.pageMenus = [ ...item ]
      state.pageMenus = state.pageMenus.map(_item => {
        const newArray = []//产品数组
        const newArray1 =[]//样品数组
         _item.submenus.forEach(element => {
           if(element.type) newArray1.push(element)
           else newArray.push(element)
        })//筛选出样品
        _item.submenus1 = newArray
        _item.submenus2 = newArray1
        return _item
      })
   
    },
    // 通过id获取某一个侧边栏的具体对象
    find_sider_elment(state, id) {
      // console.log('调用了', id, state.page_menus);
      const {siderbar_id, siderbar_child_id} = id
      // console.log('父元素', siderbar_id, siderbar_child_id);
      const select_parent_element = state.page_menus.find(_item => _item.key == siderbar_id)//找到侧边栏的大模块元素（一级侧边栏）
      // console.log('父元素', select_parent_element);
      if(select_parent_element) {
        // console.log('siderbar_child_id', siderbar_child_id == null);
        if(siderbar_child_id != null) {
          const select_child_element = select_parent_element?.submenus.find(_item => _item.id == siderbar_child_id)//找到侧边栏的大模块的子元素(二级侧边栏)
          const save_operation = select_child_element.operation.map(_item => _item.key)//将侧边栏的操作权限进行结构调整
          // console.log('子元素', select_child_element);
          // 将该页面的权限存储到本地内存当中
          localStorage.setItem('save_operation', JSON.stringify(save_operation))
        }
        localStorage.setItem('save_operation_siderbar_id', JSON.stringify(id))//同步侧边栏的索引存储到本地内存当中
        state.sider_item_index = siderbar_id//同步侧边栏索引
        // commit('systemPermissons/save_operation_permissions', null , { root: true })
      }
    },
    //获取具体siderbar侧边栏元素的索引方法
    get_sider_item_index(state, item) {
      if(JSON.parse(localStorage.getItem('save_operation_siderbar_id'))!=null) { 
        const { siderbar_id }  = JSON.parse(localStorage.getItem('save_operation_siderbar_id'))//保存active sider的key值
        state.sider_item_index = siderbar_id
      }
    },
    //获取具体siderbar子元素的索引方法
    get_sider_child_index(state, item) {
      if(JSON.parse(localStorage.getItem('save_operation_siderbar_id'))!=null) {
        const {siderbar_id, siderbar_child_id }  = JSON.parse(localStorage.getItem('save_operation_siderbar_id'))//保存active sider的key值
        const siderbar_select_element = item.find(_item => _item.key == siderbar_id)//获取当前siderbar的元素
        // console.log('siderbar_select_element', siderbar_select_element);
        const siderbar_select_child_element = siderbar_select_element?.submenus.find(_item => _item.id == siderbar_child_id)//获取当前siderbar的具体子元素
        const save_operations = siderbar_select_child_element?.operation.map(_item => _item.key)
        if(siderbar_child_id !=null && save_operations.length){
          localStorage.setItem('save_operation', JSON.stringify(save_operations)) //将当前页面的操作权限存放到本地内存对象
        }
     }
      // console.log('当前siderbar的索引元素', save_operations);
      // console.log('悬浮权限', state.sider_item_index);
    },
    update_page_menus(state, item) {
      state.page_menus = item
      // console.log('实时更新page权限meuns', state.page_menus);
    }
  },
  actions: {
    updateSave_permission({ commit }, id) {
      // console.log('调用id', id);
      commit('find_sider_elment', id)
      commit('systemPermissons/save_operation_permissions', null , { root: true })
    }
  }
}