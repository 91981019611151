import request from '@/utils/request';

// 经销商客户编号
export function agent_clients_number(params) {
  return request({ url:`/agent_clients/number/`, method:'get', params })
}
// 跟进
export function followList(params) {
  return request({ url: `/client_manages/`, method: 'get', params })
}

export function followCreate(data) {
  return request({ url: `/client_manages/`, method: 'post', data })
}

export function followUpdate(data) {
  return request({ url: `/client_manages/${data.id}/`, method: 'put', data })
}

export function followDestroy(data) {
  return request({ url: `/client_manages/${data.id}/`, method: 'delete', data })
}
// 商业机会
export function businessOpportunityList(params) {
  return request({ url: `/business_opportunity/`, method: 'get', params })
}
export function businessOpportunityCreate(data) {
  return request({ url: `/business_opportunity/`, method: 'post', data })
}

export function businessOpportunityUpdate(data) {
  return request({ url: `/business_opportunity/${data.id}/`, method: 'put', data })
}
export function businessOpportunityDestroy(data) {
  return request({ url: `/business_opportunity/${data.id}/`, method: 'delete', data })
}


// RFM分析
export function RFMList(params) {
  return request({ url: `/sales_orders/client_rfm/`, method: 'get', params })
}

// 客户画像
export function UserPersonaList(params) {
  return request({ url: `/clients/client_portrait/`, method: 'get', params })
}

// 客户跟进
export function clientFollowList(params) {
  return request({ url: `/client_manages/client_follow/`, method: 'get', params })
}
//获取客户联系人
export function ClientsContactList(params) {
  return request({ url: `/clients_contact/`, method: 'get', params })
}
//添加客户联系人
export function addClientsContact(data) {
  return request({ url: `/clients_contact/`, method: 'post', data })
}
//更新客户联系人
export function updateClientsContact(data) {
  return request({ url: `/clients_contact/${data.id}/`, method: 'put', data })
}
//获取经销商的客户
export function agentClientsList(params) {
  return request({ url: `/clients/client_agent/`, method: 'get', params })
}
export function agentClientsListAdd(data) {
  return request({ url: `/agent_clients/`, method: 'post', data })
}
export function agentClientsListPut(data) {
  return request({ url: `/agent_clients/${data.id}/`, method: 'put', data })
}
export function agentClientsListLists(params) {
  return request({ url: `/agent_clients/`, method: 'get', params })
}
export function agentClientsDestroy(data) {
  return request({ url: `/agent_clients/${data.id}/`, method: 'delete', data })
}
// 判断是否客户有跟进人
export function follower_repetition(data) {
  return request({ url: `/client_manages/follower_repetition/`, method: 'post', data })
}