import pageOperation from "@/pageOperation";
import pageMenu from "@/pageMenu1";
export default {
  namespaced:true,
  state: () => ({
    tabMenus:[],
    operation_permission:[],//操作权限的对象数组
    chart_operation:{},//图表权限的对象数组
  }),
  mutations: {
    setTabMenus(state, item) {
      state.tabMenus = item
    },
    save_operation_permissions(state, item) {
      state.operation_permission = JSON.parse(localStorage.getItem('save_operation'))//获取本地内存的操作权限
      // console.log('模块', 'save_operation_permissions');
      // console.log('权限', state.operation_permission);
    },
    visual_approval(state, item) {
      // console.log('visual_approval', item);
      const { homeData, marketData } = JSON.parse(item)
      state.chart_operation = { homeData, marketData }
    },
  },
  getters: {    
    // 筛选操作是否有权限资格
    findKeyByName:(state) => (name) => {
      const operation = pageOperation.find(_item => _item.name == name);
      return state.operation_permission.indexOf(operation.key) !== -1
    },
    // 筛选操作的key
    findKeyByName1:(state) => (name) => {
      const operation = pageOperation.find(_item => _item.name == name);
      return operation.key
    }
  },
  actions: {
    setTabMenus({ commit }, params) {
      commit('setTabMenus', params)//获取具体页面的权限
      // localStorage.setItem('save_operation', JSON.stringify(params.operation.map(_item => _item.key))) //将当前页面的操作权限存放到本地内存对象
      // console.log('权限items', params);
      if(params.length == 0) {
        commit('pageMenu/differentiate_types', pageMenu, { root: true } )
        commit('pageMenu/get_sider_item_index', pageMenu, { root:true })
        commit('pageMenu/get_sider_child_index', pageMenu, { root:true })
        commit('pageMenu/update_page_menus', pageMenu, {root:true})
      }else {
        commit('pageMenu/differentiate_types', params , { root: true })
        commit('pageMenu/get_sider_item_index', params, { root:true })
        commit('pageMenu/get_sider_child_index', params, { root:true })
        commit('pageMenu/update_page_menus', params, {root:true})
      } 
      commit('save_operation_permissions')
    }
  }

}